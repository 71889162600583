.root {
  background: #3dbe00;
  position: relative;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  height: auto;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  font-size: var(--token-DckCCvtwain);
  border-radius: 6px;
  padding: var(--token-u8uQ-APga) var(--token-TCakqYrfy);
  border: 1px solid #3dbe00;
}
@media (min-width: 1024px) {
  .root {
    font-size: var(--token--qjKwCUH1);
    padding: 12px var(--token-cTiaoKBgu);
  }
}
.rootglobal_ctaLevel_annual {
  background: #3dbe00;
}
.root:hover {
  background: #368b0c;
  border-color: #368b0c;
}
.rootglobal_ctaLevel_monthly:hover {
  text-align: right;
}
