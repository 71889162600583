.buttonMegaUltraBase:global(.__wab_instance) {
  max-width: 100%;
  flex-shrink: 1;
}
.buttonMegaUltraBasecolor_pink:global(.__wab_instance) {
  transform: translateX(0px) translateY(0px) translateZ(0px);
}
.svg__kcaTf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  padding-top: 0px;
}
.textcolor_flatGreen {
  white-space: pre;
  padding-top: 0px;
}
.textcolor_pink {
  color: #ffffff;
}
.svg__oL89 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
