.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
@media (min-width: 600px) {
  .root {
    height: 240px;
  }
}
@media (min-width: 1024px) {
  .root {
    height: 375px;
  }
}
@media (min-width: 1024px) {
  .rootglobal__010920251500021020252050_override {
    height: 375px;
  }
}
.mainContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  max-width: 100%;
  background: url("/plasmic/placeit/images/backgroundSvg.svg") top 0px left 0px / cover repeat;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 600px) {
  .mainContainer {
    padding: 0px;
  }
}
@media (min-width: 1024px) {
  .mainContainer {
    height: 375px;
    flex-shrink: 0;
  }
}
.texts {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: var(--token-cTiaoKBgu);
  padding-right: var(--token-cTiaoKBgu);
  min-width: 0;
}
@media (min-width: 600px) {
  .texts {
    width: 50%;
    padding-bottom: var(--token-TCakqYrfy);
    padding-top: var(--token-TCakqYrfy);
  }
}
@media (min-width: 900px) {
  .texts {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .texts {
    padding-top: var(--token-cTiaoKBgu);
    padding-bottom: var(--token-cTiaoKBgu);
    width: 55%;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: 0;
  }
}
@media (min-width: 1536px) {
  .texts {
    width: 640px;
    flex-shrink: 0;
  }
}
.frameText {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #fad9c5;
  border-radius: 20px;
  padding: var(--token-TCakqYrfy) var(--token-cTiaoKBgu);
}
.frameText > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 600px) {
  .frameText {
    background: #fad9c5;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 252px;
    border-radius: 20px;
  }
}
@media (min-width: 600px) {
  .frameText > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .frameText {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 900px) {
  .frameText > :global(.__wab_flex-container) {
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 900px) {
  .frameText > :global(.__wab_flex-container) > *,
  .frameText > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frameText > :global(.__wab_flex-container) > picture > img,
  .frameText
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1024px) {
  .frameText {
    background: #fad9c5;
    width: 530px;
    height: 316px;
    max-width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 20px;
  }
}
@media (min-width: 1024px) {
  .frameText > :global(.__wab_flex-container) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 1024px) {
  .frameText > :global(.__wab_flex-container) > *,
  .frameText > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .frameText > :global(.__wab_flex-container) > picture > img,
  .frameText
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1280px) {
  .frameText {
    padding-left: 43px;
    padding-right: 43px;
    padding-top: 29px;
  }
}
@media (min-width: 1536px) {
  .frameText {
    background: #fad9c5;
    width: 554px;
    height: 316px;
    flex-shrink: 1;
    border-radius: 20px;
    padding: 29px 43px 16px;
  }
}
.h1___3XWUn {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  color: #ff483f;
  text-align: center;
  text-transform: none;
  line-height: var(--token-wmteqkiSA);
  letter-spacing: 0px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
@media (min-width: 600px) {
  .h1___3XWUn {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    max-width: 178px;
  }
}
@media (min-width: 900px) {
  .h1___3XWUn {
    font-size: 26px;
    line-height: 32px;
    max-width: 274px;
  }
}
@media (min-width: 1024px) {
  .h1___3XWUn {
    font-size: 36px;
    font-weight: 600;
    line-height: 46px;
    max-width: 390px;
  }
}
@media (min-width: 1280px) {
  .h1___3XWUn {
    max-width: 482px;
  }
}
@media (min-width: 1536px) {
  .h1___3XWUn {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #ff483f;
    white-space: pre-wrap;
  }
}
@media (min-width: 1900px) {
  .h1___3XWUn {
    font-size: 36px;
  }
}
.h1global__010920251500021020252050_override___3XWUnRgEdK {
  line-height: var(--token-wmteqkiSA);
  white-space: pre-wrap;
  max-width: 178px;
  margin: 0px;
}
@media (min-width: 600px) {
  .h1global__010920251500021020252050_override___3XWUnRgEdK {
    line-height: var(--token-wmteqkiSA);
  }
}
@media (min-width: 900px) {
  .h1global__010920251500021020252050_override___3XWUnRgEdK {
    line-height: var(--token-wmteqkiSA);
    max-width: 274px;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 8px) !important;
  }
}
@media (min-width: 1024px) {
  .h1global__010920251500021020252050_override___3XWUnRgEdK {
    max-width: 390px;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 8px) !important;
  }
}
@media (min-width: 1280px) {
  .h1global__010920251500021020252050_override___3XWUnRgEdK {
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 8px) !important;
  }
}
@media (min-width: 1536px) {
  .h1global__010920251500021020252050_override___3XWUnRgEdK {
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 8px) !important;
  }
}
@media (min-width: 1900px) {
  .h1global__010920251500021020252050_override___3XWUnRgEdK {
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 8px) !important;
  }
}
.h1__kptIw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 34px;
  color: #000000d9;
  font-weight: 400;
  text-align: center;
  letter-spacing: normal;
  user-select: none;
  font-family: "SignPainter";
  padding-bottom: 0px;
  padding-top: 0px;
  font-style: normal;
  text-transform: none;
  white-space: pre-wrap;
  text-decoration-line: none;
  text-overflow: clip;
  line-height: 50px;
  min-width: 0;
}
@media (min-width: 600px) {
  .h1__kptIw {
    font-size: 34px;
    color: #ff67b5d9;
    line-height: 32px;
  }
}
@media (min-width: 900px) {
  .h1__kptIw {
    font-size: 42px;
  }
}
@media (min-width: 1024px) {
  .h1__kptIw {
    font-size: 52px;
    font-family: "SignPainter";
    width: 492px;
    height: 50px;
    line-height: 50px;
    flex-shrink: 0;
  }
}
@media (min-width: 1536px) {
  .h1__kptIw {
    font-size: 52px;
    color: #ff67b5d9;
  }
}
.h1global__010920251500021020252050_override__kptIwRgEdK {
  line-height: 32px;
  margin-top: var(--token-u8uQ-APga);
  margin-bottom: var(--token-u8uQ-APga);
}
@media (min-width: 900px) {
  .h1global__010920251500021020252050_override__kptIwRgEdK {
    margin-top: var(--token-u8uQ-APga) !important;
  }
}
@media (min-width: 1024px) {
  .h1global__010920251500021020252050_override__kptIwRgEdK {
    height: auto;
    line-height: 52px;
    margin-top: var(--token-u8uQ-APga) !important;
  }
}
@media (min-width: 1280px) {
  .h1global__010920251500021020252050_override__kptIwRgEdK {
    margin-top: var(--token-u8uQ-APga) !important;
  }
}
@media (min-width: 1536px) {
  .h1global__010920251500021020252050_override__kptIwRgEdK {
    margin-top: var(--token-u8uQ-APga) !important;
  }
}
@media (min-width: 1900px) {
  .h1global__010920251500021020252050_override__kptIwRgEdK {
    margin-top: var(--token-u8uQ-APga) !important;
  }
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  color: #994e4f;
  font-size: 12px;
  text-align: center;
  min-width: 0;
  display: none;
}
@media (min-width: 600px) {
  .text {
    display: none;
  }
}
@media (min-width: 1024px) {
  .text {
    font-size: 24px;
    font-weight: 600;
    line-height: 30.17px;
    display: block;
  }
}
@media (min-width: 1536px) {
  .text {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .textglobal__010920251500021020252050_override {
    line-height: 24px;
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
  padding: 0px;
}
@media (min-width: 900px) {
  .buttons {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
  }
}
@media (min-width: 1024px) {
  .buttons {
    justify-content: center;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.imgs {
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  min-height: 0;
  display: none;
  padding: 0px;
}
@media (min-width: 600px) {
  .imgs {
    display: flex;
  }
}
@media (min-width: 900px) {
  .imgs {
    width: 50%;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .imgs {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }
}
.imgsglobal__010920251500021020252050_override {
  display: none;
}
.img {
  position: relative;
  object-fit: cover;
  object-position: left center;
  height: 100%;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
  object-position: left center;
}
@media (min-width: 900px) {
  .img {
    width: 100%;
    min-width: 0;
  }
}
