.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.button2:global(.__wab_instance) {
  max-width: 100%;
}
.svg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.clickEventReporter__e7Ug:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.clickEventReporter___2Lhrp:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.link {
  color: #ffffff;
}
@media (min-width: 1024px) {
  .link {
    padding-right: 0px;
  }
}
.linkglobal_c1RWdyOnVipExbhYAf__2N {
  padding-right: 0px;
}
.svg__aS8Ij {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
